import React from 'react';
import Radium from 'radium';
import styles from './Tiles.module.css';
import CardGroup from 'react-bootstrap/CardGroup';
import PicTile from '../PicTile';

const Tiles = props => {

  const pics = [
    { id: 1, title: 'Mariah & Bethany Kay', src: "https://live.staticflickr.com/65535/49981336698_8e4dc5fe59_n.jpg", alt: 'Mariah &amp; Bethany Kay', cat: "fashion" },
    { id: 2, title: 'Janet & Emily', src: "https://live.staticflickr.com/65535/49359142847_75c812f6fb_n.jpg", alt: "Janet & Emily", cat: "fashion"},
    { id: 3, title: 'Erika', src: "https://live.staticflickr.com/65535/49405994618_f1787059b1_n.jpg", alt: "Erika", cat: "headshot"},
    { id: 4, title: 'Hipster-Mexico City', src: "https://live.staticflickr.com/4272/34819254141_c863dde25a_n.jpg", alt: "Hipster-Mexico City", cat: "street"},
    { id: 5, title: 'Lauren', src: "https://live.staticflickr.com/65535/49753234411_eb701d7535_n.jpg", alt: "Lauren bow", cat: "fashion"},
    { id: 6, title: 'Charli & Ayesha', src: "https://live.staticflickr.com/65535/49723425748_ce48717b40_n.jpg", alt: "Charli and Ayesha", cat: "fashion" },
    { id: 7, title: 'Silena', src: "https://live.staticflickr.com/4815/46103613815_90deed92a3_n.jpg", alt: "Silena", cat: "headshot" },
    { id: 8, title: "Takayama Students", src: "https://live.staticflickr.com/4885/44178904230_9600656648_n.jpg", alt: "Takayama Students", cat: "street" },
    { id: 9, title: "Raquel", src: "https://live.staticflickr.com/65535/49056009142_c4e5a9eb2d_n.jpg", alt: "Raquel", cat: "headshot" },
    { id: 10, title: "Ashley", src: "https://live.staticflickr.com/65535/50109539878_70d2169ddf_n.jpg", alt: "Ashley BW bridge", cat: "fashion" },
    { "id": 11, "title": "Ashley", "src": "https://live.staticflickr.com/65535/50153541446_84b0f6667a.jpg", "alt": "Ashley bright", "cat": "fashion" },
    { "id": 12, "title": "Kimberlee", "src": "https://live.staticflickr.com/65535/50417433413_a95b74beff_n.jpg", "alt": "Kimberlee", "cat": "fashion" },
     { "id": 13, "title": "Olena", src: "https://live.staticflickr.com/65535/49897981133_a86e2f0a85_w.jpg", "alt": "Olena", "cat": "fashion" },
     { "id": 14, "title": "Lauren", src: "https://live.staticflickr.com/65535/49837475643_5f61a275b0.jpg", "alt": "Lauren steps out", "cat": "fashion" },
     { "id": 15, "title": "Lauren", src: "https://live.staticflickr.com/65535/49768762508_6c13117da7_n.jpg", "alt": "Lauren BW", "cat": "headshot" },
     { "id": 15, "title": "Lauren", src: "https://live.staticflickr.com/65535/49863021856_761d7986b3_w.jpg", "alt": "Lauren BW tree", "cat": "headshot" },
     { "id": 16, "title": "Alla", src: "https://live.staticflickr.com/65535/50297309538_3617bc97ed_w.jpg", "alt": "Alla chameleon", "cat": "headshot" },
     { "id": 17, "title": "Alla", src: "https://live.staticflickr.com/65535/50499709987_f7e7163a29_w.jpg", "alt": "Alla black and green", "cat": "headshot" },
     { "id": 18, "title": "Alla", src: "https://live.staticflickr.com/65535/50489675112_ceb7402b75_w.jpg", "alt": "Alla railroad tracks", "cat": "headshot" },
     { "id": 19, "title": "Lidiya", src: "https://live.staticflickr.com/65535/50582888993_5cbbf69223_w.jpg", "alt": "Lidiya color wall bent over", "cat": "headshot" },
     { "id": 20, "title": "Lidiya", src: "https://live.staticflickr.com/65535/50589702418_f91ecd7685_w.jpg", "alt": "Lidiya color wall side lunge", "cat": "headshot" },     
     { "id": 21, "title": "Lidiya", src: "https://live.staticflickr.com/65535/50567821257_d5033b81f0_w.jpg", "alt": "Lidiya country lake", "cat": "headshot" },
     { "id": 22, "title": "Lidiya", src: "https://live.staticflickr.com/65535/50602131073_213517ebc8_w.jpg", "alt": "Lidiya color grafitti wall", "cat": "headshot" },
     { "id": 23, "title": "Sofiya", src: "https://live.staticflickr.com/65535/50565746258_ac5ae300e3_w.jpg", "alt": "Sofiya by the lake", "cat": "headshot" },
     { "id": 24, "title": "Sofiya", src: "https://live.staticflickr.com/65535/50577181588_14e9338647_w.jpg", "alt": "Sofiya dockside", "cat": "fashion" },
     { "id": 25, "title": "Sofiya", src: "https://live.staticflickr.com/65535/50577174228_2cdf676e3f_w.jpg", "alt": "Sofiya lantern", "cat": "fashion" },
     { "id": 26, "title": "Sofiya", src: "https://live.staticflickr.com/65535/50572469108_fcac48b773_w.jpg", "alt": "Sofiya autumn", "cat": "fashion" },
     { "id": 27, "title": "Angelica", src: "https://live.staticflickr.com/65535/50418252786_648a521007_w.jpg", "alt": "Angelica autumn", "cat": "headshot" },
     { "id": 28, "title": "Angelica", src: "https://live.staticflickr.com/65535/50418398407_8f3372ccb3_w.jpg", "alt": "Angelica autumn", "cat": "fashion" },
     { "id": 29, "title": "Angelica", src: "https://live.staticflickr.com/65535/50417572228_4644cf5167_w.jpg", "alt": "Angelica autumn", "cat": "headshot" },
     { "id": 30, "title": "Angelica", src: "https://live.staticflickr.com/65535/50418457017_40873a1b9a_w.jpg", "alt": "Angelica autumn", "cat": "fashion" },
     { "id": 31, "title": "Angelica", src: "https://live.staticflickr.com/65535/50507015311_c99f8ce4d3_w.jpg", "alt": "Angelica color wall", "cat": "headshot" },
     { "id": 32, "title": "Angelica", src: "https://live.staticflickr.com/65535/50509733657_aa90eda39f_w.jpg", "alt": "Angelica points BW", "cat": "headshot" },
     { "id": 33, "title": "Kimberlee & Angelica", src: "https://live.staticflickr.com/65535/50418173781_6638a52496_w.jpg", "alt": "Kimberlee & Angelica wade", "cat": "headshot" },
     { "id": 34, "title": "Kimberlee & Angelica", src: "https://live.staticflickr.com/65535/50414853321_6f8fa6735b_w.jpg", "alt": "Kimberlee & Angelica water pose", "cat": "fashion" },
     { "id": 35, "title": "Kimberlee & Angelica", src: "https://live.staticflickr.com/65535/50410453087_62aed2ece2_w.jpg", "alt": "Kimberlee & Angelica contemplate water", "cat": "headshot" },
     { "id": 36, "title": "Kimberlee & Angelica", src: "https://live.staticflickr.com/65535/50409566287_e9df55b713_w.jpg", "alt": "Kimberlee & Angelica splash", "cat": "fashion" },
     { "id": 37, "title": "Kimberlee & Angelica", src: "https://live.staticflickr.com/65535/50408491386_56a99f262f_w.jpg", "alt": "Kimberlee & Angelica water pose golden", "cat": "headshot" },
     { "id": 38, "title": "Karina", src: "https://live.staticflickr.com/65535/50403864803_7ff3ecfbba_w.jpg", "alt": "Karina", "cat": "fashion" },
     { "id": 39, "title": "Karina", src: "https://live.staticflickr.com/65535/50394812938_31fae0705b_w.jpg", "alt": "Karina", "cat": "fashion" },
     { "id": 40, "title": "Alexandra", src: "https://live.staticflickr.com/65535/50516503436_a1e9fc5e5c_w.jpg", "alt": "Alexandra", "cat": "fashion" },
     { "id": 41, "title": "Alexandra", src: "https://live.staticflickr.com/65535/50513738583_630e380f2a_w.jpg", "alt": "Alexandra", "cat": "headshot" },
     { "id": 42, "title": "Alexandra", src: "https://live.staticflickr.com/65535/50509596601_7f4e31d8bd_w.jpg", "alt": "Alexandra", "cat": "headshot" },

  ];

  const style = {
    backgroundColor: "#888888",
    color: "white",
    ':hover': {
      backgroundColor: "#666666"
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }


  let picTilesJSX = null;
  let head = null;

  if (props.match.params.category) {
    const category = props.match.params.category;
    switch(category) {
      case 'fashion':
        head = 'Fashion'
        break;
      case 'headshot':
        head = 'Headshots'
        break;
      case 'street':
        head = 'Street Photos'
        break;
      default:
        head = 'Home'
    }
  }

    picTilesJSX = (
      <>
        {pics.filter((pic, i) => {return pic.cat===props.match.params.category}).map((picTile, index) => {
          return <PicTile title={picTile.title} cat={picTile.cat} src={picTile.src} key={picTile.id} />
        })}
      </>
    );

    

    style.backgroundColor='white';
    style.color='black';
    style[':hover']={
      backgroundColor: '#888888'
    };
  // }

  return ( 

    <div className={styles.Tiles}>
      <h1 className="hr_inline">{head}</h1>
      <hr />

      <CardGroup>
            {picTilesJSX}
      </CardGroup>
    </div>
  )
};

export default Radium(Tiles);