import React from 'react';
import styles from './components/CardGroup/Tiles.module.css';
import styled from 'styled-components';

const GridWrapper = styled.div`
    margin-left: 6em;
    margin-right: 6em;
    padding: 2em;
    `;
const SlidesWrapper = styled.a`
    margin: 2em;
    `;
    export const Home = () => (
        <div className={styles.Tiles}>
        <h1 className="hr_inline">Welcome!</h1><hr />
        <GridWrapper>
            <p>
                Check out these featured photo exhibits, or choose from the categories above...
            </p>
            <SlidesWrapper>
            <a data-flickr-embed="true" href="https://www.flickr.com/photos/30087170@N00/albums/72157715010947807" title="2020 BLM March">
                <img src="https://live.staticflickr.com/65535/50044194877_0d860120c9.jpg" width="500" height="375" alt="2020 BLM March" />
            </a></SlidesWrapper>
            <SlidesWrapper>
            <a data-flickr-embed="true" href="https://www.flickr.com/photos/30087170@N00/albums/72157712430932441" title="Raquel">
                <img src="https://live.staticflickr.com/65535/49045664411_efce5f49fa_w.jpg" width="500" height="375" alt="Raquel" />
            </a></SlidesWrapper>

            {/* <a data-flickr-embed="true" href="https://www.flickr.com/photos/30087170@N00/albums/72157665462238948" title="Shannon">
                <img src="https://live.staticflickr.com/4740/40117702851_ff58452996.jpg" width="500" height="375" alt="Shannon" />
            </a> */}
            <SlidesWrapper>
                <a data-flickr-embed="true" href="https://www.flickr.com/photos/30087170@N00/albums/72157694932474990" title="Ashley">
                    <img src="https://live.staticflickr.com/1860/29288575487_c3edffae49_w.jpg" width="375" height="500" alt="Ashley"/>
                </a>
            </SlidesWrapper>
        </GridWrapper>
        </div>
    )