import React, { useState } from "react";
import Radium from "radium";
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';
import FlipCard from './FlipCard';
import './App.css';

function Navheader() {

  var mql = window.matchMedia("(max-width: 992px)")
  let wideScreen = !(mql.matches);

  const [ menuState, setMenuState ] = useState({ 
    showPicMenu: wideScreen
  });

  function selectPicOrWordMenu(mql) {
    if (mql.matches) { // If media query matches (narrow screen)
      setMenuState({showPicMenu: false});
    } else { // if media query doesn't match (wider screen)
      setMenuState({showPicMenu: true});
    }
    console.log(menuState);
  }  

  mql.addListener(selectPicOrWordMenu) // Attach listener function on state changes

  return (
    <>
      <Navbar className="container-fluid" sticky="top" bg="light" variant="light" expand="lg" id="btm-navbar">
        <img src={require('./images/Untitled.png')} alt='DR Design' />
        <Navbar.Brand className="ml-3" href="/home" title='Home'>
          <h1>Daniel Rangel Gallery</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          { 
            menuState.showPicMenu ?
          <Nav className="mr-auto justify-content-center">
            <Navbar.Brand href='/samples/fashion' title='Fashion'>
                <FlipCard className=''
                src="https://live.staticflickr.com/65535/49359142847_75c812f6fb_n.jpg" 
                alt="Fashion" 
                title="Fashion"
                width="30"
                height="30" />
            </Navbar.Brand>  
            <Navbar.Brand href="/samples/headshot" title='Headshots' onClick={() => console.log('headshots clicked')}>
                <FlipCard src="https://live.staticflickr.com/65535/50342456061_a120179742_s.jpg" alt="Headshots" title="Headshots" />
            </Navbar.Brand>
            <Navbar.Brand href="/samples/street" title='Street Photos'>
                <FlipCard src="https://live.staticflickr.com/4272/34819254141_c863dde25a_n.jpg" alt="Street Photos" title="Street Photos" />
            </Navbar.Brand>
            <Navbar.Brand className="text-nav-card text-nav-card-ml vert_ctr" href="/portfolio" title='Portfolio'>Portfolio</Navbar.Brand>
            <Navbar.Brand className="text-nav-card vert_ctr" href="/about" title="About"><span>About</span></Navbar.Brand>
          </Nav>
        :
          <Nav>
            <Nav.Link href="/samples/fashion" title='Fashion'>Fashion</Nav.Link>
            <Nav.Link href="/samples/headshot">Headshots</Nav.Link>
            <Nav.Link href="/samples/street">Street Photos</Nav.Link>
            <Nav.Link href="/portfolio">Portfolio</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
          </Nav>
          }
        </Navbar.Collapse>
      </Navbar>
      <i class="fa fa-angle-double-down" aria-hidden="true"></i>

      <br />
    </>
  )
}

export default Radium(Navheader);