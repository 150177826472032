import React from 'react';
import styled from 'styled-components';
// import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

const GridWrapper = styled.div`
    // display: grid;
    // grid-gap: 10px;
    // margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    // grid-template-columns: repeat(12,1fr);
    // grid-template-columns: repeat(3,1fr);
    // grid-auto-columns: minmax(25px, auto);
    // grid-auto-columns: minmax(250px, auto);
    `;

    export const About = (props) => (
        <GridWrapper>
            <br />
            <h1>
                ABOUT the photographer
            </h1>
            <br />

            <hr />
            <br />
            <img src={require('./images/Untitled.png')} width="50" height="50" alt='DR Design' />
            <br />
            <br />
            <p>
            Daniel Rangel is a portrait and street photographer based out of Chicago, IL. Largely self-taught, his work ranges from individual portraiture to professional head shots, modeling, and published street photography.
            </p>
            <br/>
            <br/>
            <img src={require('./images/dfgLI3.jpg')} width="400" alt='The photographer poses with Picasso' />

            <p>
            <br />
            <br />

            In addition to his professional work, Daniel continues to innovate through experimentation with editing techniques. Though he has had a camera in his hands for more than three decades, Daniel began his professional career in photography in 1991, when he had his first solo show at the Lizard Lounge. Since then he has had the opportunity to work with a wide range of individuals, ranging from models to professional actors, and corporate professionals.
            </p>
            <br/>
            <p>
            Subjects appreciate his relaxed style, friendly demeanor, and sense of humor. Using his passion of street photography and urban settings, Daniel utilizes the cityscape for his photography as well as local parks whenever possible in his portraits
            </p>
            <br/>
            <br/>
            <p>
            <strong>Daniel’s Portrait Expertise Includes:</strong>
            </p>
            {/* <ListGroup horizontal={'md'} className="my-2">
                <ListGroup.Item variant="secondary">Fashion</ListGroup.Item>
                <ListGroup.Item>Corporate Headshots</ListGroup.Item>
                <ListGroup.Item>Modeling</ListGroup.Item>
                <ListGroup.Item>Actor Headshots</ListGroup.Item>
                <ListGroup.Item>Stage Promos</ListGroup.Item>
                <ListGroup.Item>Travels to Chicago</ListGroup.Item>
                <ListGroup.Item>Dancers</ListGroup.Item>
                <ListGroup.Item>Performance Art</ListGroup.Item>
                <ListGroup.Item>Concert</ListGroup.Item>
            </ListGroup> */}
            {/* <Container>
                <Row>
                    <Col>Fashion</Col>
                    <Col>Corporate Headshots</Col>
                    <Col>Modeling</Col>
                </Row>
                <Row>
                    <Col>Actor Headshots</Col>
                    <Col>Stage Promos</Col>
                    <Col>Travels to Chicago</Col>
                </Row>
                <Row>
                    <Col>Dancers</Col>
                    <Col>Performance Art</Col>
                    <Col>Concert</Col>                    
                </Row>
            </Container> */}
            <Table responsive="md" borderless="true" striped="true" hover="true" >
                <tbody>
                <tr>
                    <td className="align-middle">Fashion</td>
                    <td className="align-middle">Corporate Headshots</td>
                    <td className="align-middle">Modeling</td>
                </tr>
                <tr>
                    <td className="align-middle">Actor Headshots</td>
                    <td className="align-middle">Stage Promos</td>
                    <td className="align-middle">Travels to Chicago</td>
                </tr>
                <tr>
                    <td className="align-middle">Dancers</td>
                    <td className="align-middle">Performance Art</td>
                    <td className="align-middle">Concert</td>
                </tr>
                </tbody>
            </Table>


            {/* <ul>
                <li>Fashion</li>
                <li>Corporate Headshots</li>
                <li>Modeling</li>
                <li>Actor Headshots</li>
                <li>Stage Promos</li>
                <li>Travels to Chicago</li>
                <li>Dancers</li>
                <li>Performance Art</li>
                <li>Concert</li>
            </ul> */}
            <br />
            <br />
            <p>
            The number one way to get the most of your portraits is get your subject to feel at ease and relaxed in front of the camera.  If we have not worked together in the past, let’s chat to get a feel for one another and hear what your goals are.
            </p>
            <br/>
            <p>
            Call or text to discuss your vision: 773.960.9286
            </p>
                {/* <img src="https://live.staticflickr.com/8204/8188287831_883bcff2c4_s.jpg" width="75" height="75" alt="DR" />                 */}
            <br />
            <hr />
            
            <br />
            <br />
            <small className="text-muted">
            The content of these images CANNOT BE COPIED, DISTRIBUTED or PUBLISHED for any media, electronic or otherwise without my permission. The utilization in other web pages without the express written consent of the author is PROHIBITED.
            </small>
        </GridWrapper>
    )
