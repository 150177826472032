import React from "react";
import './FlipCard.css';
// import img_avatar2 from './images/img_avatar2.png';

// let flipstyles = {
//     width:'300px',
//     height:'300px'
//   };

  let flipstyles = {
    width:'150px',
    height:'150px',
    resizeMode: 'contain'
  };

function FlipCard(props) {
    return (
        // <div className="flip-card">
        //     <h1>{props.name}</h1>
        //     <p>{props.title}</p>
        // </div>
        // <>
        // <div className="flip-card-outer">
            <div className="flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        {/* <img src={img_avatar2} alt="Avatar-lady" style={flipstyles} /> */}
                        <img 
                            style={flipstyles} 
                            src={props.src} 
                            alt={props.alt} 
                            width={props.width}
                            height={props.height}
                        ></img>
                        <div className="centered">{props.title}</div>
                    </div>
                    <div className="flip-card-back">
                        {/* <div> */}
                        {/* <h1>{props.name}</h1>  */}
                        <p className="vert_ctr">{props.title}</p> 
                        {/* <p className="vert_ctr">{props.tagline}</p> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        // </div>
        // </>
    );
}

export default FlipCard;