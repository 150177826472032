import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './components/CardGroup/Tiles.module.css';
import styled from 'styled-components';

const GridWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    margin-top: 1em;
    margin-left: 6em;
    margin-right: 6em;
    grid-template-columns: repeat(12,1fr);
    grid-auto-columns: minmax(25px, auto);
    `;

let styles1 = {
    margin: 'auto',
    width: '500px'
    };

    export const Portfolio = () => (
        <div className={styles.Tiles}>
        <h1 className="hr_inline">Portfolio</h1><hr />

            <div style={styles1}>
                <Carousel>
                    <div>
                        <img src="https://live.staticflickr.com/65535/49684738412_f968a62b6b.jpg" alt="Brooklyn Bridge" />
                        <p className="legend">New York</p>

                    </div>
                    <div>
                        <img src="https://live.staticflickr.com/1977/44838841325_455cdd5d6e.jpg" alt="Kobe"/>
                        <p className="legend">Kobe, Japan</p>

                    </div>
                    <div>
                        <img src="https://live.staticflickr.com/65535/49532088821_2e81f94339.jpg" alt="The Crab Pot"/>
                        <p className="legend">Seattle</p>

                    </div>
                    <div>
                        <img src="https://live.staticflickr.com/5579/15065140377_f42335f929.jpg" alt="Millenium Park"/>
                        <p className="legend">Chicago</p>

                    </div>
                </Carousel>
            </div>
            <GridWrapper>
            <p>
            </p>

            </GridWrapper>
        </div>
    )
