import React from 'react';
import Jumbotron from './Jumbotron';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navheader from './Navheader';
import { Home } from './Home';
// import { Fashion } from './Fashion';
// import { Street } from './Street';
import { Portfolio } from './Portfolio';
import { About } from './About';
import { NoMatch } from './NoMatch';
import './App.css';
import Tiles from './components/CardGroup/Tiles';

function App() {

  return (
    <div className="App">

      <header className="App-header">

        <div className="offset-cropped">
          {/* <img alt='DR Design' /> */}
          {/* <img src={require('./images/banner_big.jpg')} alt='DR Design Banner' /> */}
          {/* <img src={require('./images/8021068499_7f75cbcb2b_k.jpg')} alt='DR Design Banner' /> */}
          {/* <img src={require('./images/8021068499_8757ff6d6a_c.jpg')} alt='DR Design Banner' /> */}
          {/* <img src="https://live.staticflickr.com/105/coverphoto/30087170@N00_h.jpg?1481727353#30087170@N00" alt={'Cubs rally header'}></img> */}
        </div>

        <React.Fragment>
          <Router basename={process.env.PUBLIC_URL}>
            <Navheader />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/samples/:category" component={Tiles} />
              {/* <Route path="/fashion" component={Fashion} /> */}
              {/* <Route path="/headshots" component={Tiles} category="headshot" heading="Headshots" /> */}
              {/* <Route path="/street" component={Street} /> */}
              <Route path="/portfolio" component={Portfolio} />
              <Route path="/about" component={About} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </React.Fragment>


      </header>
      <Jumbotron content="ALL RIGHTS RESERVED©" className="footer" />

    </div>
  );
}

export default App;

