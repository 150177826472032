import React from "react";

function Jumbotron(props) {
  return (
    <div className="jumbotron jumbotron-fluid mb-0">
      <div className="container">{props.children}
        <h1 className="lead">{props.content}</h1>
      </div>
    </div>
  )
}

export default Jumbotron;