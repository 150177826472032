import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from './CardGroup/Tiles.module.css';
import './PicTile.css';

const picTile = (props) => {
    return (
      <Card className={styles.Tiles}>
        <Card.Img variant="top" src={props.src} />
        <Card.Body>
        <Card.Title><h3>{props.title}</h3></Card.Title>
          {/* <Card.Text> */}
            {/* Description here...  */}
          {/* </Card.Text> */}
        </Card.Body>
        {/* <Card.Footer>{props.title}</Card.Footer> */}

      </Card>
    )
};

export default picTile;